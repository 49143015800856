import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Welcome to storeybox.com</h1>
      </header>
      <p>Future site of some cool shit. Keep checking in to see progress.</p>
      <h2>Who am I?</h2>
      <p>I'm Jeff Storey, hit me up: jmstorey01@yahoo.com</p>
      <h3>Some of my interests:</h3>
      <ul>
        <li>Coding: Mainly Javascript(Node, React)</li>
        <li>Music: Heavy Metal, Progressive Rock</li>
        <li>Guitar: Complete Hack</li>
      </ul>
    </div>
  );
}

export default App;
